<style lang="less" scoped>
.aboutUs {
  width: 100%;
  background: #070911;
}
.aboutUs-details {
  width: 76.7%;
  height: 100%;
  max-width: 1104px;
  margin: 0 auto;
  padding: 196px 0;
  color: rgba(222, 230, 255, 1);
  &-first {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 49.8%;
      img {
        width: 100%;
      }
    }
    &-right {
      width: 43.1%;
      p {
        margin: 0;
        font-family: "PingFangSC-Regular", "PingFang SC";
        color: rgba(222, 230, 255, 1);
        font-weight: 400;
        &:nth-child(1) {
          font-size: 30px;
          line-height: 42px;
          letter-spacing: 4px;
        }
        &:nth-child(2) {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 4px;
          margin: 5px 0 28px;
        }
        &:nth-child(3) {
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 2px;
        }
      }
    }
  }
  &-second {
    margin-top: 120px;
  }
  .title,
  .normal {
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 2px;
  }
  .title {
    font-size: 20px;
  }
  &-third {
    margin-top: 101px;
    position: relative;
    img {
      width: 100%;
    }
    &-main {
      position: absolute;
      top: 70px;
      left: 637px;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 4px;
      }
      p:nth-child(2) {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 4px;
      }
      p:nth-child(3),
      p:nth-child(5) {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
      }
      p:nth-child(4),
      p:nth-child(6) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>

<template>
  <div class="aboutUs">
    <div class="aboutUs-details">
      <div class="aboutUs-details-first">
        <div class="aboutUs-details-first-left">
          <img src="../assets/Bitmap.png"
               alt="">
        </div>
        <div class="aboutUs-details-first-right">
          <p>{{$t('Q1')}}</p>
          <p>{{$t('Q2')}}</p>
          <p>
            {{$t('Q3')}}
          </p>
          <img src="../assets/weibo.png"
               alt=""
               style="width: 30px; marginTop: 33px; cursor: pointer"
               @click="linkTo('https://weibo.com/u/6096290535', '_blank')">
          <img src="../assets/weixin.png"
               alt=""
               style="width: 30px; marginLeft: 30px; cursor: pointer"
               @click="linkTo('https://mp.weixin.qq.com/s/Xpwm4f_M0hJEGKGVZsf_6Q', '_blank')">
        </div>
      </div>
      <div class="aboutUs-details-second">
        <p class="normal">
          {{$t('Q4')}} <br /> <br />
          {{$t('Q5')}} <br /> <br />
          {{$t('Q6')}}
        </p>
        <p class="title">
          {{$t('Q7')}}
        </p>
        <p class="normal">
          {{$t('Q8')}} <br />
          {{$t('Q26')}}
        </p>
        <p class="title">
          {{$t('Q9')}}
        </p>
        <p class="normal">
          {{$t('Q10')}}<br />
          {{$t('Q11')}}<br />
          {{$t('Q12')}}<br />
          {{$t('Q13')}}<br />
          {{$t('Q14')}}
        </p>
      </div>
      <div class="aboutUs-details-third">
        <img src="../assets/map_card.png"
             alt="">
        <div class="aboutUs-details-third-main">
          <p>
            {{$t('Q15')}}
          </p>
          <p>
            {{$t('Q16')}}
          </p>
          <p>
            {{$t('Q17')}}
          </p>
          <p>
            {{$t('Q18')}} <br />
            {{$t('Q19')}} <br />
            {{$t('Q20')}} <br />
            {{$t('Q21')}} <br />
            {{$t('Q22')}}
          </p>
          <p>
            {{$t('Q23')}}
          </p>
          <p>
            {{$t('Q24')}}<br />
            {{$t('Q25')}}
          </p>
        </div>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities';
import CommonFooter from '../components/common-footer';
export default {
  name: 'aboutUs-details',
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonActivies,
    CommonFooter
  },
}
</script>
